import React, { useEffect, useState, useContext, useRef, useMemo } from 'react'
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber'
import { Color, SphereBufferGeometry } from 'three'
import {
    MapControls,
    OrbitControls,
} from 'three/examples/jsm/controls/OrbitControls'
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'

import { getFeatures } from '../shared/utils'
import { TooltipContext } from '../shared/TooltipContext'
import { CurrentDashboardContext } from '../../../../../wrappers/CurrentDashboardContext'
import { MapContext } from '../../MapContext'
import { orbitControlsProps } from '../../../../../../utils/widgets/map/3d/config'
import SceneContextProvider, { SceneContext } from '../shared/SceneContext'
import { CrossSectionContext } from './CrossSectionContext'

extend({
    MapControls,
    TrackballControls,
    OrbitControls,
    ConvexGeometry,
})

const PointerGeometry = ({ position, color = 'white' }) => {
    return (
        <mesh position={position} visible={!!position}>
            <sphereBufferGeometry args={[0.5, 32, 32]} attach={'geometry'} />
            <meshBasicMaterial attach={'material'} color={color} />
        </mesh>
    )
}

const LineGeometry = ({ p0, p1, color }) => {
    if (p0 && p1) {
        return (
            <line>
                <geometry attach="geometry" vertices={[p0, p1]} />
                <lineBasicMaterial attach="material" color={color} />
            </line>
        )
    }
    return null
}

const Controls = ({ camera, gl, centerPoint }) => {
    const isControlled = useRef(false)
    const controlsRef = useRef(null)
    const lightRef = useRef(null)
    const {
        compassRef,
        tooltipContext: { updateTooltipState },
        activePoint,
        crossSectionToolData,
        crossSectionToolBuffer,
        crossSectionToolEnabled,
        cameraControlProps,
        cameraVector,
    } = useContext(SceneContext)

    useFrame((state) => {
        // update isControlled flag on the scene - determines whether interactions should happen
        // (such as tooltip display / hide) when the controls are
        // updating the camera position
        state.scene.userData.isControlled = isControlled.current
        controlsRef.current.update()
        lightRef.current.position.set(
            ...state.camera.position.clone().multiplyScalar(2).toArray()
        )

        // apply transformation to compass based on azimuthal angle of camera
        // compassRef.current.style.transform = 'rotateX(' + controlsRef.current.getPolarAngle() + 'rad) rotateZ(' + controlsRef.current.getAzimuthalAngle() + 'rad)'
        compassRef.current.style.transform =
            'rotate(' + controlsRef.current.getAzimuthalAngle() + 'rad)'
    })

    useEffect(() => {
        if (controlsRef.current) {
            controlsRef.current.addEventListener('start', () => {
                isControlled.current = true
                updateTooltipState(null)
            })
            controlsRef.current.addEventListener('end', () => {
                isControlled.current = false
            })
        }
    }, [controlsRef, isControlled])

    const crossSectionToolGeometry = useMemo(() => {
        if (
            activePoint &&
            crossSectionToolEnabled
        ) {
            // console.log('activePoint:', activePoint, 'crossSectionToolData:', crossSectionToolData)
            if (
                crossSectionToolData &&
                crossSectionToolData.p0 &&
                crossSectionToolData.p1
            ) {
                const { p0, p1 } = crossSectionToolData
                return (
                    <>
                        <PointerGeometry position={p0} color="red" />
                        <PointerGeometry position={p1} color="red" />
                        <LineGeometry p0={p0} p1={p1} color="red" />
                        <PointerGeometry position={activePoint} />
                    </>
                )
            } else if (crossSectionToolData && crossSectionToolData.p0) {
                const { p0 } = crossSectionToolData
                return (
                    <>
                        <PointerGeometry position={p0} color="red" />
                        <PointerGeometry position={activePoint} />
                    </>
                )
            } else {
                return <PointerGeometry position={activePoint} />
            }
        }
    }, [activePoint, crossSectionToolData, crossSectionToolEnabled])

    return (
        <>
            {crossSectionToolGeometry}
            <pointLight intensity={1} ref={lightRef} color={'white'} />
            <orbitControls
                {...cameraControlProps}
                args={[camera, gl.domElement]}
                ref={controlsRef}
            />
        </>
    )
}

const Renderer = ({ data }) => {
    const { camera, gl } = useThree()
    const { expandedWidgetId, cameraVector, scale } = useContext(SceneContext)
    const [features, setFeatures] = useState([])
    const [centerPoint, setCenterPoint] = useState([0, 0, 0])

    // set the features based on layer data
    useEffect(() => {
        const [features, centerPoint] = getFeatures(
            camera,
            gl,
            data,
            scale,
            cameraVector
        )
        setFeatures(features)
        setCenterPoint(centerPoint)
    }, [camera, gl, data, cameraVector, scale])

    //

    // manually fire a resize event for when the
    // canvas size changes: see https://github.com/react-spring/react-three-fiber/issues/350
    // for tracking of this issue
    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
    }, [expandedWidgetId])

    const objects = useMemo(
        () => (
            <>
                <group position={[0, 0, 0]}>{features}</group>
                <Controls camera={camera} centerPoint={centerPoint} gl={gl} />
            </>
        ),
        [features, camera, gl, centerPoint]
    )

    return objects
}

export default ({
    data,
    compassRef,
    cameraVector,
    scale,
    cameraControlProps = orbitControlsProps,
}) => {
    const tooltipContext = useContext(TooltipContext)
    const {
        addPointToCrossSection,
        activePoint,
        setActivePoint,
        crossSectionToolData,
        crossSectionToolBuffer,
        crossSectionToolEnabled,
        crossSectionPlane
    } = useContext(CrossSectionContext)
    const {
        expandedWidgetId,
        sharedPageId,
        sharedPageKey,
        setSharedPageId,
    } = useContext(CurrentDashboardContext)
    const { legendState } = useContext(MapContext)

    return (
        <Canvas
            camera={{ position: [0, -11, 0.75] }}
            color={'grey'}
            concurrent={true}
            onCreated={({ gl }) => {
                gl.setClearColor(new Color('#0f0f0f'))
            }}
            onPointerDown={addPointToCrossSection}
        >
            {/* <fog attach="fog" args={['grey', 0, 100]} /> */}
            <SceneContextProvider
                tooltipContext={tooltipContext}
                activePoint={activePoint}
                setActivePoint={setActivePoint}
                crossSectionPlane={crossSectionPlane}
                addPointToCrossSection={addPointToCrossSection}
                crossSectionToolData={crossSectionToolData}
                crossSectionToolBuffer={crossSectionToolBuffer}
                crossSectionToolEnabled={crossSectionToolEnabled}
                expandedWidgetId={expandedWidgetId}
                sharedPageId={sharedPageId}
                setSharedPageId={setSharedPageId}
                sharedPageKey={sharedPageKey}
                compassRef={compassRef}
                legendState={legendState}
                cameraVector={cameraVector}
                cameraControlProps={cameraControlProps}
                scale={scale}
            >
                <Renderer data={data} />
            </SceneContextProvider>
        </Canvas>
    )
}
export { SceneContext }
