import React, { useContext } from 'react'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import NoDataComponent from '../NoDataComponent'

const ImageWidget = () => {
    const { widgetData: widget } = useContext(WidgetDataContext)
    const imageSource = widget ? widget.FeatureFiles : null
    if (!(imageSource)) {
        return <NoDataComponent />
    }
    return (
        <div className="imageWrapper">
            <img className="imageContent" src={imageSource} />
        </div>
    )

}

export default ImageWidget