import React, { useRef, useMemo, useContext, useCallback } from 'react'
import { useFrame, useUpdate } from 'react-three-fiber'
import { Vector3, Color, Shape, Vector2, ArrowHelper, Vector4 } from 'three'
import { SceneContext } from './SceneContext'
import { generateMeshFromPointCloud, getVoxelWidth } from './utils'
import {
    meshBasicMaterialProps,
    meshProps,
} from '../../../../../../utils/widgets/map/3d/config'

const Mesh = ({ feature, centerPoint, style, scale }) => {
    const shouldInteract = useRef(true)

    useFrame((state) => {
        const isControlled = state.scene.userData?.isControlled
        shouldInteract.current = !isControlled
    })

    const {
        tooltipContext: { updateTooltipState },
        legendState,
    } = useContext(SceneContext)
    const { geometry, styleData: featureStyles } = feature
    const { coordinates } = geometry

    const styleObj = useMemo(
        () => ({
            ...featureStyles,
            ...style,
        }),
        [featureStyles, style]
    )

    const vertices = useMemo(() => {
        return generateMeshFromPointCloud(coordinates[0], centerPoint, scale)
    }, [coordinates, centerPoint])

    const visible = useMemo(
        () =>
            legendState.find(
                (legendItem) => legendItem.layerName === feature.layerName
            ).visible,
        [legendState, feature]
    )

    const geometryRef = useUpdate(
        (geometry) => {
            geometry.setFromPoints(vertices)
        },
        [visible]
    )

    const Meshes = useMemo(
        () => (
            <mesh
                {...meshProps}
                name={feature.layerName}
                userData={feature.tooltipData}
                onPointerEnter={(e) => {
                    if (shouldInteract.current) {
                        const data = e.eventObject.userData
                        const event = e.nativeEvent
                        const { offsetX, offsetY, target } = event
                        updateTooltipState({
                            data,
                            target: {
                                offsetX,
                                offsetY,
                                scrollWidth: target.scrollWidth,
                                clientHeight: target.clientHeight,
                            },
                        })
                    }
                }}
                onPointerLeave={(e) => {
                    if (shouldInteract.current) {
                        e.eventObject.material.color = new Color(
                            styleObj.color ? styleObj.color : 'red'
                        )
                        if (visible) {
                            updateTooltipState(null)
                        }
                    }
                }}
            >
                <bufferGeometry attach="geometry" ref={geometryRef} />
                <meshBasicMaterial
                    {...meshBasicMaterialProps}
                    color={styleObj.color ? styleObj.color : 'red'}
                    opacity={styleObj.opacity ? styleObj.opacity : 0.3}
                />
            </mesh>
        ),
        [shouldInteract, styleObj, updateTooltipState, feature]
    )

    return visible && Meshes
}

const Polygon = ({ feature, centerPoint, style, scale}) => {
    const shouldInteract = useRef(true)

    useFrame((state) => {
        const isControlled = state.scene.userData?.isControlled
        shouldInteract.current = !isControlled
    })

    const {
        tooltipContext: { updateTooltipState },
        legendState,
    } = useContext(SceneContext)

    const geometryRef = useRef()
    const { geometry, styleData: featureStyles  } = feature
    const { coordinates } = geometry

    
    const styleObj = useMemo(
        () => ({
            ...featureStyles,
            ...style,
        }),
        [featureStyles, style]
    )

    const displayTooltip = useMemo(() => {
        return styleObj && styleObj.hasOwnProperty('displayTooltip') ? styleObj.displayTooltip : false
    }, [styleObj])

    const topValue = useMemo(() => {
        if (feature && feature.data) {
            let topField = 'Top'
            if (style.hasOwnProperty('topField')) {
                topField = style.topField
            }
            if (feature.data.hasOwnProperty(topField)) {
                return feature.data[topField]
            }
        }
        return null
    }, [feature, feature.data, style])

    const bottomValue = useMemo(() => {
        if (feature && feature.data) {
            let bottomField = 'Bottom'
            if (style.hasOwnProperty('bottomField')) {
                bottomField = style.bottomField
            }
            // console.log('bottomField:', bottomField)
            if (feature.data.hasOwnProperty(bottomField)) {
                return feature.data[bottomField]
            }
        }
        return null
    }, [feature, feature.data, style])

    const shape = useMemo(() => {
        const vectors = coordinates
            ? coordinates[0].reduce((acc, pt) => {
                  if (pt.length > 1) {
                      return [
                          ...acc,
                          new Vector2(
                              (pt[0] - centerPoint.x) * scale.x,
                              (pt[1] - centerPoint.y) * scale.y
                          ),
                      ]
                  }
                  return acc
              }, [])
            : []
        // console.log('centerPoint:', centerPoint)
        return new Shape(vectors)
    }, [coordinates, centerPoint])

    if (
        !(
            feature.data &&
            typeof topValue != 'undefined' &&
            topValue != null &&
            typeof bottomValue != 'undefined' &&
            bottomValue != null
        )
    ) {
        return null
    }

    // calculate height as the bottom - top scaled in the z direction
    // and the bottom as the bottom - centerPoint.z scaled in the z direction
    const bottom = (bottomValue - centerPoint.z) * scale.z
    const height = Math.abs(topValue - bottomValue) * scale.z

    const visible = legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible

    return (
        visible && (
            <mesh
                {...meshProps}
                name={feature.layerName}
                position={[0, bottom , 0]}
                userData={feature.tooltipData}
                onPointerEnter={(e) => {
                    if (shouldInteract.current && displayTooltip) {
                        const data = e.eventObject.userData
                        const event = e.nativeEvent
                        const { offsetX, offsetY, target } = event
                        updateTooltipState({
                            data,
                            target: {
                                offsetX,
                                offsetY,
                                scrollWidth: target.scrollWidth,
                                clientHeight: target.clientHeight,
                            },
                        })
                    }
                }}
                onPointerLeave={(e) => {
                    if (shouldInteract.current && displayTooltip) {
                        e.eventObject.material.color = new Color(
                            styleObj.color ? styleObj.color : 'red'
                        )
                        if (visible) {
                            updateTooltipState(null)
                        }
                    }
                }}
            >
                <extrudeBufferGeometry
                    attach="geometry"
                    ref={geometryRef}
                    args={[
                        shape,
                        { steps: 1, depth: height, bevelEnabled: false },
                    ]}
                />
                <meshBasicMaterial
                    {...meshBasicMaterialProps}
                    color={style.color ? style.color : 'red'}
                    opacity={style.opacity ? style.opacity : 0.3}
                />
            </mesh>
        )
    )
}

const Line = ({ feature, centerPoint, style: layerStyle, camera, scale }) => {
    const shouldInteract = useRef(true)
    useFrame((state) => {
        const isControlled = state.scene.userData?.isControlled
        shouldInteract.current = !isControlled
    })
    const {
        tooltipContext: { updateTooltipState },
        sharedPageKey,
        sharedPageId,
        setSharedPageId,
        legendState,
    } = useContext(SceneContext)
    const { geometry, styleData: featureStyle } = feature
    const { coordinates } = geometry

    const style = useMemo(() => ({ ...layerStyle, ...featureStyle }), [
        layerStyle,
        featureStyle,
    ])

    const sections = useMemo(() => {
        let lastZValue = 0
        const vectors = coordinates.map((pt, idx) => {
            if (pt.length > 2) {
                return new Vector3(
                    (pt[0] - centerPoint.x) * scale.x,
                    (pt[1] - centerPoint.y) * scale.y,
                    (pt[2] - centerPoint.z) * scale.z
                )
            } else {
                // TODO: fix on sql side
                // if 2 pt wellbore, and there is no last z coordinate,
                // get the z value of the last pt
                if (idx > 0 && coordinates[idx - 1].length > 2) {
                    lastZValue = coordinates[idx - 1][2] - centerPoint.z
                }
                return new Vector3(
                    (pt[0] - centerPoint.x) * scale.x,
                    (pt[1] - centerPoint.y) * scale.y,
                    lastZValue * scale.z
                )
            }
        })
        const lineSegments = vectors.reduce((acc, curr, idx) => {
            if (idx <= vectors.length - 2) {
                const v1 = vectors[idx]
                const v2 = vectors[idx + 1]
                return [
                    ...acc,
                    {
                        v1,
                        v2,
                    },
                ]
            } else {
                return acc
            }
        }, [])

        const cylinders = lineSegments.map(({ v1, v2 }) => {
            const direction = new Vector3().subVectors(v2, v1)
            const arrow = new ArrowHelper(direction.clone().normalize(), v1)
            const rotation = arrow.rotation.clone()
            const position = new Vector3().addVectors(
                v1,
                direction.clone().multiplyScalar(0.5)
            )
            return {
                direction,
                rotation,
                position,
            }
        })
        return cylinders
    }, [coordinates, centerPoint])

    const getColor = useCallback(
        (f, s) => {
            const c = new Color(s.color ? s.color : 'red')
            if (sharedPageKey && sharedPageId) {
                if (sharedPageId === f[sharedPageKey])
                    return new Color('#ADD8E6')
            }
            return c
        },
        [sharedPageKey, sharedPageId]
    )

    const lineWidth = useMemo(() => (style.width ? style.width * 0.02 : 0.1), [
        style,
    ])

    const material = useMemo(() => {
        return (
            <meshBasicMaterial
                {...meshBasicMaterialProps}
                color={getColor(feature, style)}
                opacity={style.opacity ? style.opacity : 0.3}
            />
        )
    }, [getColor, feature, style])

    const visible = useMemo(
        () =>
            legendState.find(
                (legendItem) => legendItem.layerName === feature.layerName
            ).visible,
        [legendState, feature]
    )

    const meshes = useMemo(() => {
        return sections.map(
            ({ direction, rotation, quaternion, position }, idx) => (
                <mesh
                    key={`${feature.layerName}-line-${idx}`}
                    castShadow={meshProps.castShadow}
                    userData={feature.tooltipData}
                    onPointerEnter={(e) => {
                        if (shouldInteract.current) {
                            const data = e.eventObject.userData
                            const event = e.nativeEvent
                            const { offsetX, offsetY, target } = event
                            e.eventObject.material.color = new Color('black')
                            updateTooltipState({
                                data,
                                target: {
                                    offsetX,
                                    offsetY,
                                    scrollWidth: target.scrollWidth,
                                    clientHeight: target.clientHeight,
                                },
                            })
                        }
                    }}
                    onPointerLeave={(e) => {
                        if (shouldInteract.current) {
                            e.eventObject.material.color = new Color(
                                style.color ? style.color : 'red'
                            )
                            if (visible) {
                                updateTooltipState(null)
                            }
                        }
                    }}
                    onClick={() => {
                        if (sharedPageKey) {
                            setSharedPageId(feature[sharedPageKey])
                        }
                    }}
                    rotation={[rotation.x, rotation.y, rotation.z]}
                    quaternion={quaternion}
                    position={position}
                    name={feature.layerName}
                >
                    <cylinderBufferGeometry
                        args={[
                            lineWidth,
                            lineWidth,
                            direction.length(),
                            32,
                            1,
                            true,
                        ]}
                        attach={'geometry'}
                    />
                    {material}
                </mesh>
            )
        )
    }, [
        material,
        sharedPageKey,
        setSharedPageId,
        shouldInteract,
        updateTooltipState,
        visible,
        lineWidth,
        sections,
        feature,
        style.color,
    ])

    const meshGroup = useMemo(
        () => <group rotation={meshProps.rotation}>{meshes}</group>,
        [meshes]
    )

    return <>{material && sections && visible && meshGroup}</>
}

const PointCloud = ({ feature, centerPoint, style, scale }) => {
    // THL - 8.9.2023 - to enable the point cloud tooltip, change shouldInteract default false to default true
    // and uncomment the useFrame ref
    const shouldInteract = useRef(false)
    // useFrame((state) => {
    //     const isControlled = state.scene.userData?.isControlled
    //     shouldInteract.current = !isControlled
    // })
    const {
        tooltipContext: { updateTooltipState },
        addPointToCrossSection,
        setActivePoint,
        clippingPlanes,
        legendState,
    } = useContext(SceneContext)

    const geometryRef = useRef()
    const { geometry, styleData: featureStyles } = feature
    const { coordinates } = geometry

    const styleObj = useMemo(
        () => ({
            ...featureStyles,
            ...style,
        }),
        [featureStyles, style]
    )

    const particleCount = geometry.coordinates[0].length
    const positionArray = useMemo(() => {
        const coords = geometry.coordinates[0]
        const pos = new Float32Array(3 * coords.length)
        for (var i = 0; i < coords.length; i++) {
            let idx = i * 3
            const point = coords[i]
            pos[idx] = (point[0] - centerPoint.x) * scale.x
            pos[idx + 1] = (point[1] - centerPoint.y) * scale.y
            pos[idx + 2] = (point[2] - centerPoint.z) * scale.z
        }
        return pos
    }, [coordinates, centerPoint])

    const voxelSize = useMemo(() => {
        if (positionArray) {
            const buffer = 0.15
            const xValues = positionArray.filter((_, i) => i % 3 === 0)
            const yValues = positionArray.filter((_, i) => i % 3 === 1)
            const zValues = positionArray.filter((_, i) => i % 3 === 2)
            const xWidths = getVoxelWidth(xValues)
            const yWidths = getVoxelWidth(yValues)
            const zWidths = getVoxelWidth(zValues)
            return Math.max(xWidths.median + buffer, yWidths.median + buffer, zWidths.median + buffer)
        }
        return 0.1
    }, [positionArray])

    const userDataArray = useMemo(() => {
        const uData = geometry.data
        return uData
    }, [geometry])

    // const onPointerEnter = useCallback((e) => {
    //     if (shouldInteract.current) {
    //         const { intersections } = e
    //         const minDistance = Math.min(...intersections.map(x => x.distanceToRay))
    //         const closestDistanceIntersectionIdx = intersections.findIndex(x => x.distanceToRay === minDistance)
    //         const closestDistanceIntersection = intersections.find(x => x.distanceToRay === minDistance)
    //         const tooltipData = e.eventObject.userData[closestDistanceIntersectionIdx]
    //         const event = e.nativeEvent
    //         const { offsetX, offsetY, target } = event
    //         e.eventObject.material.color = new Color('white')
    //         updateTooltipState({
    //             data: tooltipData,
    //             target: {
    //                 offsetX,
    //                 offsetY,
    //                 scrollWidth: target.scrollWidth,
    //                 clientHeight: target.clientHeight,
    //             },
    //         })
    //     }
    // }, [updateTooltipState])

    // const onPointerLeave = useCallback((e) => {
    //     if (shouldInteract.current) {
    //         e.eventObject.material.color = new Color(
    //             style.color ? style.color : 'red'
    //         )
    //         if (visible) {
    //             updateTooltipState(null)
    //         }
    //     }
    // }, [updateTooltipState])

    const onPointerEnter = useCallback(
        (e) => {
            if (setActivePoint) {
                const { intersections } = e
                const minDistance = Math.min(...intersections.map(x => x.distance))
                const closestDistanceIntersectionIdx = intersections.findIndex(
                    (x) => x.distance === minDistance
                )
                const closestDistanceIntersection =
                    intersections[closestDistanceIntersectionIdx]
                if (closestDistanceIntersection) {
                    const v = closestDistanceIntersection.point
                    setActivePoint(v)
                }
            }
        },
        [setActivePoint]
    )

    const material = useMemo(() => {
        return (
            <pointsMaterial
                        transparent={true}
                        clippingPlanes={clippingPlanes ? clippingPlanes : []}
                        size={voxelSize}
                        opacity={styleObj.opacity ? styleObj.opacity : 0.3}
                        color={styleObj.color}
                        attach="material"
                    >
                    </pointsMaterial>
        )}, [ styleObj, voxelSize, clippingPlanes])

    const visible = legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible

    const group = useMemo(() => {
        return (
            <group rotation={meshProps.rotation} visible={visible}>
                <points
                    onPointerEnter={onPointerEnter}
                    userData={userDataArray}
                >
                    <bufferGeometry attach="geometry">
                        <bufferAttribute
                            attachObject={['attributes', 'position']}
                            count={particleCount}
                            array={positionArray}
                            itemSize={3}
                        />
                    </bufferGeometry>
                    {material}
                </points>
                    {/* {clippingPlanes && clippingPlanes.map((plane, idx) => {
                        return (
                            <mesh key={idx}>
                                <planeHelper args={[plane, 1000, idx==0 ? 'red' : 'blue']} />
                                <meshBasicMaterial attach={'material'} color={'red'} />
                            </mesh>
                        )
                    })} */}
            </group>
        )
    }, [positionArray, particleCount, visible, material])

    return <>{group}</>
}

export { Polygon, Line, Mesh, PointCloud }
