import React, { useEffect, useState, useContext, useRef, useMemo } from 'react'
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber'
import { Color, Plane, PlaneGeometry, Vector3 } from 'three'
import {
    MapControls,
    OrbitControls,
} from 'three/examples/jsm/controls/OrbitControls'
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'

import { getFeatures } from '../shared/utils'
import { TooltipContext } from '../shared/TooltipContext'
import { CurrentDashboardContext } from '../../../../../wrappers/CurrentDashboardContext'
import { MapContext } from '../../MapContext'
import { orbitControlsProps } from '../../../../../../utils/widgets/map/3d/config'
import SceneContextProvider, { SceneContext } from '../shared/SceneContext'
import CrossSectionContextProvider, {
    CrossSectionContext,
} from './CrossSectionContext'

extend({
    MapControls,
    TrackballControls,
    OrbitControls,
    ConvexGeometry,
})

const Controls = ({ camera, gl }) => {
    const isControlled = useRef(false)
    const controlsRef = useRef(null)
    const lightRef = useRef(null)
    const {
        tooltipContext: { updateTooltipState },
        cameraControlProps,
        crossSectionPlane,
        crossSectionMidPoint,
    } = useContext(SceneContext)

    useFrame((state) => {
        // update isControlled flag on the scene - determines whether interactions should happen
        // (such as tooltip display / hide) when the controls are
        // updating the camera position
        state.scene.userData.isControlled = isControlled.current
        controlsRef.current.update()
        lightRef.current.position.set(
            ...state.camera.position.clone().multiplyScalar(2).toArray()
        )


        // apply transformation to compass based on azimuthal angle of camera
        // compassRef.current.style.transform = 'rotateX(' + controlsRef.current.getPolarAngle() + 'rad) rotateZ(' + controlsRef.current.getAzimuthalAngle() + 'rad)'
        // compassRef.current.style.transform =
        //     'rotate(' + controlsRef.current.getAzimuthalAngle() + 'rad)'
    })

    useEffect(() => {
        if (controlsRef.current) {
            controlsRef.current.addEventListener('start', () => {
                isControlled.current = true
                updateTooltipState(null)
            })
            controlsRef.current.addEventListener('end', () => {
                isControlled.current = false
            })
        }
    }, [controlsRef, isControlled])


    useEffect(() => {
        if (crossSectionPlane && crossSectionMidPoint) {
            controlsRef.current.minPolarAngle = Math.PI / 2
            controlsRef.current.maxPolarAngle = Math.PI / 2
            camera.position.set(
                crossSectionMidPoint.x + crossSectionPlane.normal.x * 30,
                crossSectionMidPoint.y + crossSectionPlane.normal.z * 30,
                crossSectionMidPoint.z + crossSectionPlane.normal.y * 30,
            )
            controlsRef.current.target.set(crossSectionMidPoint.x, crossSectionMidPoint.y, crossSectionMidPoint.z)

        } else {
            // controlsRef.current.maxPolarAngle = Infinity
            // controlsRef.current.minPolarAngle = -Infinity
            
        }
    }, [crossSectionPlane, crossSectionMidPoint, controlsRef])

    return (
        <>
            <pointLight intensity={1} ref={lightRef} color={'white'} />
            <orbitControls
                {...cameraControlProps}
                args={[camera, gl.domElement]}
                ref={controlsRef}
            />
        </>
    )
}

const Renderer = ({ data }) => {
    const { camera, gl } = useThree()
    const { expandedWidgetId, cameraVector, scale } = useContext(SceneContext)
    const [features, setFeatures] = useState([])
    const [centerPoint, setCenterPoint] = useState([0, 0, 0])

    // set the features based on layer data
    useEffect(() => {
        const [features, centerPoint] = getFeatures(
            camera,
            gl,
            data,
            scale,
            cameraVector
        )
        setFeatures(features)
        setCenterPoint(centerPoint)
    }, [camera, gl, data, cameraVector, scale])

    //

    // manually fire a resize event for when the
    // canvas size changes: see https://github.com/react-spring/react-three-fiber/issues/350
    // for tracking of this issue
    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
    }, [expandedWidgetId])

    const objects = useMemo(
        () => (
            <>
                <group position={[0, 0, 0]}>{features}</group>
                <Controls camera={camera} centerPoint={centerPoint} gl={gl} />
            </>
        ),
        [features, camera, gl, centerPoint]
    )

    return objects
}

export default ({
    data,
    scale,
    cameraVector,
    cameraControlProps = orbitControlsProps,
}) => {
    const tooltipContext = useContext(TooltipContext)
    const {
        clippingPlanes,
        crossSectionToolBuffer,
        crossSectionPlane,
        crossSectionToolEnabled,
        crossSectionToolData,
        addPointToCrossSection,
        crossSectionMidPoint,
    } = useContext(CrossSectionContext)
    const {
        expandedWidgetId,
        sharedPageId,
        sharedPageKey,
        setSharedPageId,
    } = useContext(CurrentDashboardContext)
    const { legendState } = useContext(MapContext)

    return (
        <>
            <Canvas
                camera={{ position: [0, -11, 0.75] }}
                color={'grey'}
                concurrent={true}
                onCreated={({ gl }) => {
                    gl.setClearColor(new Color('#0f0f0f'))
                    gl.localClippingEnabled = true
                }}
            >
                {/* <fog attach="fog" args={['grey', 0, 100]} /> */}
                <SceneContextProvider
                    tooltipContext={tooltipContext}
                    expandedWidgetId={expandedWidgetId}
                    clippingPlanes={clippingPlanes}
                    crossSectionPlane={crossSectionPlane}
                    crossSectionMidPoint={crossSectionMidPoint}
                    addPointToCrossSection={addPointToCrossSection}
                    crossSectionToolData={crossSectionToolData}
                    crossSectionToolBuffer={crossSectionToolBuffer}
                    crossSectionToolEnabled={crossSectionToolEnabled}
                    sharedPageId={sharedPageId}
                    setSharedPageId={setSharedPageId}
                    sharedPageKey={sharedPageKey}
                    legendState={legendState}
                    cameraVector={cameraVector}
                    cameraControlProps={cameraControlProps}
                    scale={scale}
                >
                    <Renderer data={data} />
                </SceneContextProvider>
            </Canvas>
        </>
    )
}
