import React, {useContext} from 'react'
import { getLegendDataFromRange } from './styleFunctions'
import { formatNumber } from '../../../../../utils/numberFormatting'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'


export default ({dataRange}) => {
    const { showLegend } = useContext(WidgetDataContext)
    const legendData = getLegendDataFromRange(dataRange)
    return (
        <div className="mapLegendWrapper" style={{display: showLegend ? '' : 'none'}}>
            {legendData.map((legendItem, idx) => (
                <div key={`legend-item-${idx}`} className="mapLegendItemWrapper">
                    <div className="mapLegendItemColor">
                        <span
                            className="mapLegendItemSwatch"
                            style={{ backgroundColor: legendItem.color }}
                        />
                    </div>
                    <div className="mapLegendItemText">
                        {`${formatNumber(legendItem.dataMin, 'compact')}${legendItem.dataMax == null ? '' : `- ${formatNumber(legendItem.dataMax, 'compact')}`}`}
                    </div>
                </div>
            ))}
        </div>
    )
}
