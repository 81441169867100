import React, { useContext } from 'react'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

import formatValue from '../../../../utils/valueFormatting'
import stringFields from '../../../../utils/constants/widgets/stringFields'
import getHiddenColumns from '../../../../utils/widgets/getHiddenColumns'

export default () => {
    const { widgetData: widget } = useContext(WidgetDataContext)

    // data is the first row of the widget.Data field
    const data = (widget && widget.Data && widget.Data.length) ? widget.Data[0] : null
    const hiddenColumns = widget ? getHiddenColumns(widget) : []
    // map the data ({<field1>: <value1>, <field2>: <value2>, ...})
    // into an array of field, value pairs
    const fieldData = Object.keys(data).filter(field => !hiddenColumns.includes(field)).map(field => ({field, value: data[field]}))

    // arrange the field data and display
    return (
        <div className="columns is-full formWrapper">
            <div className="column is-full">
                <div className="columns is-multiline is-full">
                    {fieldData.map((f, idx) => (
                        <div key={`form-${idx}`}className="column formFieldWrapper">    
                            <div className="field is-horizontal formField">
                                <label className="label is-size-7">
                                    {`${f.field} :  `}
                                </label>
                                <div className="control formValue is-size-7">{stringFields.includes(f.field) ? f.value : formatValue(f.value)} </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}