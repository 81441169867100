import React, { createContext } from 'react'

const SceneContext = createContext(null)

const SceneContextProvider = ({tooltipContext, activePoint, setActivePoint, crossSectionMidPoint, crossSectionPlane, clippingPlanes, crossSectionToolData, crossSectionToolEnabled, crossSectionToolBuffer, addPointToCrossSection, expandedWidgetId, sharedPageId, setSharedPageId, sharedPageKey, compassRef, legendState, cameraVector, cameraControlProps, scale, children }) => {
    return (
        <SceneContext.Provider
            value={{
                tooltipContext,
                activePoint, 
                setActivePoint,
                addPointToCrossSection,
                crossSectionPlane,
                crossSectionMidPoint,
                crossSectionToolData,
                crossSectionToolEnabled,
                crossSectionToolBuffer,
                clippingPlanes,
                expandedWidgetId: expandedWidgetId,
                sharedPageId: sharedPageId,
                setSharedPageId: setSharedPageId,
                sharedPageKey: sharedPageKey,
                compassRef: compassRef,
                legendState: legendState,
                cameraVector: cameraVector,
                cameraControlProps: cameraControlProps,
                scale: scale,
            }}
        >
            {children}
        </SceneContext.Provider>
    )
}

const withSceneContext = (WrappedComponent) => {
    return (props) => (
        <SceneContextProvider 
            compassRef={props.compassRef} 
            cameraVector={props.cameraVector}
            scale={props.scale} 
            cameraControlProps={props.cameraControlProps}
        >
            <WrappedComponent {...props} />
        </SceneContextProvider>
    )
}

export default SceneContextProvider
export { SceneContext }
