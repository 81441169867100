import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'

import { WidgetDataContext } from '../../../../../wrappers/WidgetDataContext'
import { prepareData, getLegendDataFromLayers } from '../shared/utils'
import MapLegend from '../shared/MapLegend'
import MapTooltip from '../../Tooltip'
import TooltipContextProvider, {
    TooltipContext,
} from '../shared/TooltipContext'
import { CurrentDashboardContext } from '../../../../../wrappers/CurrentDashboardContext'
import MapContextProvider from '../../MapContext'
import {
    scale as defaultScale,
    fixedViewOrbitControlProps,
    orthogonalViewOrbitControlProps,
} from '../../../../../../utils/widgets/map/3d/config'
import { Vector3 } from 'three'
import CrossSectionBuilderScene from './CrossSectionBuilderScene'
import CrossSectionViewerScene from './CrossSectionViewerScene'
import { CrossSectionContextProvider } from './CrossSectionContext'
import { Leva } from 'leva'

const getTooltipDataFromFeature = (tooltipState) => {
    if (tooltipState) {
        const { data } = tooltipState
        return Object.keys(data).map((fieldName) => ({
            field: fieldName,
            value: data[fieldName],
        }))
    }
    return []
}

const verticalVector = new Vector3(0, 0, 85)

export default () => {
    const { sharedPageKey } = useContext(CurrentDashboardContext)
    const { loading, widgetData: widget } = useContext(WidgetDataContext)
    const mapRef = useRef(null)
    const compassRef = useRef(null)
    const [layerData, setLayerData] = useState([])
    const scale = useMemo(() => {
        if (widget && widget.Data) {
            const { WidgetOptions: stringifiedOptions } = widget
            // parse the options object and get the "layers" field
            let parsedOptions = stringifiedOptions
                ? JSON.parse(stringifiedOptions)
                : { layers: [] }
            let { scale: customScale } = parsedOptions
            if (!customScale) {
                customScale = defaultScale
            }
            return customScale
        }
        return defaultScale
    }, [widget, widget.Data])

    useEffect(() => {
        if (widget && widget.Data) {
            // obtain layer data + stringified options object from
            // the widget
            const { Data: data, WidgetOptions: stringifiedOptions } = widget

            // parse the options object and get the "layers" field
            let parsedOptions = stringifiedOptions
                ? JSON.parse(stringifiedOptions)
                : { layers: [] }
            let { layers: options } = parsedOptions
            // prepare data - turns into shape
            // [{layerName: <layerName_1>, layerType: <layerType_1>, data: <data_1>, style: <style_1>}, ...]
            const layerData = prepareData(data, options, sharedPageKey, scale)
            setLayerData(layerData)
        }
    }, [widget, sharedPageKey, scale])
    
    if (loading) {
        return null
    }

    return (
        <div className="mapFlexWrapper">
            <MapContextProvider
                data={layerData}
                legendFunction={getLegendDataFromLayers}
            >
                <MapLegend />
                <CrossSectionContextProvider>
                    <div className="crossSectionMapFlexWrapper">

                        <div className="crossSectionMapWrapper">
                            <img
                                ref={compassRef}
                                src={'/compass.svg'}
                                className="compass"
                                alt={'3d map compass'}
                            />
                            <TooltipContextProvider>
                                <div
                                    ref={mapRef}
                                    className={`mapContainer ${
                                        loading ? 'is-hidden' : ''
                                    }`}
                                >
                                    <CrossSectionBuilderScene
                                        data={layerData}
                                        compassRef={compassRef}
                                        cameraVector={verticalVector}
                                        cameraControlProps={
                                            fixedViewOrbitControlProps
                                        }
                                        scale={scale}
                                    />
                                </div>
                                <TooltipContext.Consumer>
                                    {({ tooltipState }) => (
                                        <MapTooltip
                                            {...tooltipState}
                                            tooltipData={getTooltipDataFromFeature(
                                                tooltipState
                                            )}
                                        />
                                    )}
                                </TooltipContext.Consumer>
                            </TooltipContextProvider>
                        </div>
                        <div className="crossSectionDivider"></div>
                        <div className="crossSectionMapWrapper">
                            <TooltipContextProvider>
                                <div
                                    ref={mapRef}
                                    className={`mapContainer crossSectionMapContainer ${
                                        loading ? 'is-hidden' : ''
                                    }`}
                                >
                                    <Leva titleBar={false} />
                                    <CrossSectionViewerScene
                                        data={layerData}
                                        compassRef={compassRef}
                                        cameraVector={verticalVector}
                                        cameraControlProps={
                                            orthogonalViewOrbitControlProps
                                        }
                                        scale={scale}
                                    />
                                </div>
                                <TooltipContext.Consumer>
                                    {({ tooltipState }) => (
                                        <MapTooltip
                                            {...tooltipState}
                                            tooltipData={getTooltipDataFromFeature(
                                                tooltipState
                                            )}
                                        />
                                    )}
                                </TooltipContext.Consumer>
                            </TooltipContextProvider>
                        </div>
                    </div>
                </CrossSectionContextProvider>
            </MapContextProvider>
        </div>
    )
}
