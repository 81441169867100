
import { DoubleSide } from "three"

const meshProps = {
    rotation: [-Math.PI / 2, 0, 0],
    castShadow: false
}

const meshBasicMaterialProps = {
    side: DoubleSide, 
    attach: "material", 
    transparent: true,
    depthTest: false
}

const orbitControlsProps = {
    rotateSpeed: 0.35,
    screenSpacePanning: true,
}

const fixedViewOrbitControlProps = {
        enableZoom: true, 
        enableRotate: false,
        enablePan: false,
        rotateSpeed: 0.35,
        screenSpacePanning: true,
}

const orthogonalViewOrbitControlProps = {
    enableZoom: true, 
    enableRotate: true,
    // maxPolarAngle: Math.PI / 2,
    // minPolarAngle: Math.PI / 2,
    enablePan: false,
    rotateSpeed: 0.35,
    screenSpacePanning: true,
}

const scale = { x: 1250, y: 1250, z: 1 / 500 }

export { scale, meshProps, meshBasicMaterialProps, orbitControlsProps, fixedViewOrbitControlProps, orthogonalViewOrbitControlProps}