import { button, useControls } from 'leva'
import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Plane, Vector3 } from 'three'

export const CrossSectionContext = createContext(null)


export const CrossSectionContextProvider = ({ children }) => {
    const {
        crossSectionToolBuffer,
        crossSectionToolEnabled,
    } = useControls(
        'Cross Section Tool',
        {
            crossSectionToolEnabled: { label: 'On/Off', value: true },
            crossSectionToolBuffer: {
                label: 'Buffer',
                value: 0.01,
                min: 0.01,
                max: 1,
            },
            clear: button(() => {
                clearCrossSectionToolData()
            }),
        },
        { titleBar: false }
    )

    const [activePoint, setActivePoint] = useState(null)
    const [crossSectionData, setCrossSectionData] = useState(null)
    const [crossSectionToolData, setCrossSectionToolData] = useState({
        enabled: false,
        p0: null,
        p1: null,
    })

    const clearCrossSectionToolData = useCallback(() => {
        setCrossSectionToolData((prevToolData) => ({
            ...prevToolData,
            p0: null,
            p1: null,
        }))
    }, [setCrossSectionToolData])

    const setCrossSectionToolActive = useCallback(
        (active) => {
            setCrossSectionToolData({ ...crossSectionToolData, active })
        },
        [crossSectionToolData]
    )

    const setCrossSectionToolEnabled = useCallback(
        (enabled) => {
            setCrossSectionToolData({ ...crossSectionToolData, enabled })
        },
        [crossSectionToolData]
    )

    const addPointToCrossSection = useCallback(() => {
        const point = activePoint
        if (!crossSectionToolData.p0) {
            setCrossSectionToolData((prevToolData) => ({
                ...prevToolData,
                p0: point,
            }))
            return
        } else if (crossSectionToolData.p0 && !crossSectionToolData.p1) {
            setCrossSectionToolData((prevToolData) => ({
                ...prevToolData,
                p1: point,
            }))
            return
        } else if (crossSectionToolData.p0 && crossSectionToolData.p1) {
            setCrossSectionToolData((prevToolData) => ({
                ...prevToolData,
                p0: point,
                p1: null,
            }))
            return
        } else if (crossSectionToolData.p0) {
            setCrossSectionToolData((prevToolData) => ({
                ...prevToolData,
                p1: point,
            }))
            return
        } else if (!crossSectionToolData.p0 && !crossSectionToolData.p1) {
            setCrossSectionToolData((prevToolData) => ({
                ...prevToolData,
                p0: point,
            }))
            return
        } else {
            setCrossSectionToolData((prevToolData) => ({
                ...prevToolData,
                p0: point,
                p1: null,
            }))
            return
        }
    }, [crossSectionToolData, setCrossSectionToolData, activePoint])

    const crossSectionPlane = useMemo(() => {  
        const { p0, p1 } = crossSectionToolData
        if (p0 && p1) {
            // // old calculation:
            // // find a plane that is parallel to the z axis and passes through both points
            // // 1. find the normal vector of the plane by taking the cross product of the z axis and the vector between the two points
            // const normal = new Vector3().crossVectors(p1, p0).normalize()
            // // 2. find the constant of the plane by taking the dot product of the normal vector and one of the points
            // const constant = normal.dot(p0)
            // // 3. create a plane object from those two parameters
            // return new Plane(normal, constant)

            // new calculation: (shifted z and parallel to z axis)
            // find a plane that is parallel to the z axis and passes through both points
            // 1. find the normal vector of the plane by taking the cross product of the z axis and the vector between the two points
            const differenceVector = new Vector3().subVectors(p1, p0)
            const normal = new Vector3().crossVectors(new Vector3(p1.x, p1.y, 0), new Vector3(p0.x, p0.y, 0)).normalize()
            
            // 2. find the constant of the plane by taking the dot product of the normal vector and one of the points
            const constant = normal.dot(new Vector3(p0.x, p0.y, p0.z))
            // 3. create a plane object from those two parameters
            const plane = new Plane(normal, constant)
            // plane
            // .translate(new Vector3(0, 0, Math.abs(p0.z + p1.z) / 2))
            return plane

        }
        return null
    }, [crossSectionToolData])

    const crossSectionMidPoint = useMemo(() => {
        const { p0, p1 } = crossSectionToolData
        if (p0 && p1) {
            return new Vector3((p0.x + p1.x) / 2, (p0.y + p1.y) / 2, (p0.z + p1.z) / 2)
        }
    }, [crossSectionToolData])

    const clippingPlanes = useMemo(() => {
        const { p0, p1 } = crossSectionToolData
        if (p0 && p1) {
            let plane1 = new Plane().copy(crossSectionPlane)
            let plane2 = new Plane().copy(crossSectionPlane)

            
            const positiveTranslation = new Vector3(0, 0, crossSectionToolBuffer * 100)
            const negativeTranslation = new Vector3(0, 0, -crossSectionToolBuffer * 100)
            if (p0.x > p1.x) {
                if (p0.y > p1.y) {
                    return [plane1.negate().translate(negativeTranslation), plane2.translate(positiveTranslation)]
                } else {
                    return [plane1.translate(positiveTranslation), plane2.negate().translate(negativeTranslation)]
                }
            } else {
                if (p0.y > p1.y) {
                    return [plane1.negate().translate(positiveTranslation), plane2.translate(negativeTranslation)]
                } else {
                    return [plane1.translate(negativeTranslation), plane2.negate().translate(positiveTranslation)]
                }
            }
        }
        return []
    }, [
        crossSectionToolData,
        crossSectionPlane,
        crossSectionToolBuffer
    ])

    return (
        <CrossSectionContext.Provider
            value={{
                crossSectionData,
                setCrossSectionData,
                crossSectionPlane,
                crossSectionToolData,
                setCrossSectionToolData,
                clearCrossSectionToolData,
                setCrossSectionToolActive,
                setCrossSectionToolEnabled,
                activePoint,
                setActivePoint,
                crossSectionToolBuffer,
                clippingPlanes,
                crossSectionMidPoint,
                crossSectionToolEnabled,
                addPointToCrossSection,
            }}
        >
            {children}
        </CrossSectionContext.Provider>
    )
}
